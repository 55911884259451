/****** Proxima Nova Extra Condensed - it's added in index.html *******/
// it's implemented with fonts.adobe.com. It's connected with impact it-support mail

/****** Saira Font *******/
@font-face {
    font-family: 'Saira';
    src:
        local('Saira Light Italic'),
        local('Saira-LightItalic'),
        url('/assets/fonts/Saira/Saira-LightItalic.woff2') format('woff2'),
        url('/assets/fonts/Saira/Saira-LightItalic.woff') format('woff');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Saira';
    src:
        local('Saira ExtraLight Italic'),
        local('Saira-ExtraLightItalic'),
        url('/assets/fonts/Saira/Saira-ExtraLightItalic.woff2') format('woff2'),
        url('/assets/fonts/Saira/Saira-ExtraLightItalic.woff') format('woff');
    font-weight: 200;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Saira';
    src:
        local('Saira Black'),
        local('Saira-Black'),
        url('/assets/fonts/Saira/Saira-Black.woff2') format('woff2'),
        url('/assets/fonts/Saira/Saira-Black.woff') format('woff');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Saira';
    src:
        local('Saira SemiBold'),
        local('Saira-SemiBold'),
        url('/assets/fonts/Saira/Saira-SemiBold.woff2') format('woff2'),
        url('/assets/fonts/Saira/Saira-SemiBold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Saira';
    src:
        local('Saira SemiBold Italic'),
        local('Saira-SemiBoldItalic'),
        url('/assets/fonts/Saira/Saira-SemiBoldItalic.woff2') format('woff2'),
        url('/assets/fonts/Saira/Saira-SemiBoldItalic.woff') format('woff');
    font-weight: 600;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Saira';
    src:
        local('Saira Thin'),
        local('Saira-Thin'),
        url('/assets/fonts/Saira/Saira-Thin.woff2') format('woff2'),
        url('/assets/fonts/Saira/Saira-Thin.woff') format('woff');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Saira';
    src:
        local('Saira Black Italic'),
        local('Saira-BlackItalic'),
        url('/assets/fonts/Saira/Saira-BlackItalic.woff2') format('woff2'),
        url('/assets/fonts/Saira/Saira-BlackItalic.woff') format('woff');
    font-weight: 900;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Saira';
    src:
        local('Saira ExtraLight'),
        local('Saira-ExtraLight'),
        url('/assets/fonts/Saira/Saira-ExtraLight.woff2') format('woff2'),
        url('/assets/fonts/Saira/Saira-ExtraLight.woff') format('woff');
    font-weight: 200;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Saira';
    src:
        local('Saira Bold Italic'),
        local('Saira-BoldItalic'),
        url('/assets/fonts/Saira/Saira-BoldItalic.woff2') format('woff2'),
        url('/assets/fonts/Saira/Saira-BoldItalic.woff') format('woff');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Saira';
    src:
        local('Saira Light'),
        local('Saira-Light'),
        url('/assets/fonts/Saira/Saira-Light.woff2') format('woff2'),
        url('/assets/fonts/Saira/Saira-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Saira';
    src:
        local('Saira Bold'),
        local('Saira-Bold'),
        url('/assets/fonts/Saira/Saira-Bold.woff2') format('woff2'),
        url('/assets/fonts/Saira/Saira-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Saira';
    src:
        local('Saira Medium'),
        local('Saira-Medium'),
        url('/assets/fonts/Saira/Saira-Medium.woff2') format('woff2'),
        url('/assets/fonts/Saira/Saira-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Saira';
    src:
        local('Saira ExtraBold'),
        local('Saira-ExtraBold'),
        url('/assets/fonts/Saira/Saira-ExtraBold.woff2') format('woff2'),
        url('/assets/fonts/Saira/Saira-ExtraBold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Saira';
    src:
        local('Saira Thin Italic'),
        local('Saira-ThinItalic'),
        url('/assets/fonts/Saira/Saira-ThinItalic.woff2') format('woff2'),
        url('/assets/fonts/Saira/Saira-ThinItalic.woff') format('woff');
    font-weight: 100;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Saira';
    src:
        local('Saira Regular'),
        local('Saira-Regular'),
        url('/assets/fonts/Saira/Saira-Regular.woff2') format('woff2'),
        url('/assets/fonts/Saira/Saira-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Saira';
    src:
        local('Saira ExtraBold Italic'),
        local('Saira-ExtraBoldItalic'),
        url('/assets/fonts/Saira/Saira-ExtraBoldItalic.woff2') format('woff2'),
        url('/assets/fonts/Saira/Saira-ExtraBoldItalic.woff') format('woff');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Saira';
    src:
        local('Saira Medium Italic'),
        local('Saira-MediumItalic'),
        url('/assets/fonts/Saira/Saira-MediumItalic.woff2') format('woff2'),
        url('/assets/fonts/Saira/Saira-MediumItalic.woff') format('woff');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Saira';
    src:
        local('Saira Italic'),
        local('Saira-Italic'),
        url('/assets/fonts/Saira/Saira-Italic.woff2') format('woff2'),
        url('/assets/fonts/Saira/Saira-Italic.woff') format('woff');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

/****** Saira Extra Condensed Font *******/
@font-face {
    font-family: 'Saira ExtraCondensed';
    src:
        local('Saira ExtraCondensed Thin'),
        local('SairaExtraCondensed-Thin'),
        url('/assets/fonts/Saira_ExtraCondensed/SairaExtraCondensed-Thin.woff2') format('woff2'),
        url('/assets/fonts/Saira_ExtraCondensed/SairaExtraCondensed-Thin.woff') format('woff');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Saira ExtraCondensed';
    src:
        local('Saira ExtraCondensed Bold'),
        local('SairaExtraCondensed-Bold'),
        url('/assets/fonts/Saira_ExtraCondensed/SairaExtraCondensed-Bold.woff2') format('woff2'),
        url('/assets/fonts/Saira_ExtraCondensed/SairaExtraCondensed-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Saira ExtraCondensed';
    src:
        local('Saira ExtraCondensed Light'),
        local('SairaExtraCondensed-Light'),
        url('/assets/fonts/Saira_ExtraCondensed/SairaExtraCondensed-Light.woff2') format('woff2'),
        url('/assets/fonts/Saira_ExtraCondensed/SairaExtraCondensed-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Saira ExtraCondensed';
    src:
        local('Saira ExtraCondensed Bold Italic'),
        local('SairaExtraCondensed-BoldItalic'),
        url('/assets/fonts/Saira_ExtraCondensed/SairaExtraCondensed-BoldItalic.woff2') format('woff2'),
        url('/assets/fonts/Saira_ExtraCondensed/SairaExtraCondensed-BoldItalic.woff') format('woff');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Saira ExtraCondensed';
    src:
        local('Saira ExtraCondensed Black Italic'),
        local('SairaExtraCondensed-BlackItalic'),
        url('/assets/fonts/Saira_ExtraCondensed/SairaExtraCondensed-BlackItalic.woff2') format('woff2'),
        url('/assets/fonts/Saira_ExtraCondensed/SairaExtraCondensed-BlackItalic.woff') format('woff');
    font-weight: 900;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Saira ExtraCondensed';
    src:
        local('Saira ExtraCondensed Thin Italic'),
        local('SairaExtraCondensed-ThinItalic'),
        url('/assets/fonts/Saira_ExtraCondensed/SairaExtraCondensed-ThinItalic.woff2') format('woff2'),
        url('/assets/fonts/Saira_ExtraCondensed/SairaExtraCondensed-ThinItalic.woff') format('woff');
    font-weight: 100;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Saira ExtraCondensed';
    src:
        local('Saira ExtraCondensed Black'),
        local('SairaExtraCondensed-Black'),
        url('/assets/fonts/Saira_ExtraCondensed/SairaExtraCondensed-Black.woff2') format('woff2'),
        url('/assets/fonts/Saira_ExtraCondensed/SairaExtraCondensed-Black.woff') format('woff');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Saira ExtraCondensed';
    src:
        local('Saira ExtraCondensed ExtraLight Italic'),
        local('SairaExtraCondensed-ExtraLightItalic'),
        url('/assets/fonts/Saira_ExtraCondensed/SairaExtraCondensed-ExtraLightItalic.woff2') format('woff2'),
        url('/assets/fonts/Saira_ExtraCondensed/SairaExtraCondensed-ExtraLightItalic.woff') format('woff');
    font-weight: 200;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Saira ExtraCondensed';
    src:
        local('Saira ExtraCondensed SemiBold Italic'),
        local('SairaExtraCondensed-SemiBoldItalic'),
        url('/assets/fonts/Saira_ExtraCondensed/SairaExtraCondensed-SemiBoldItalic.woff2') format('woff2'),
        url('/assets/fonts/Saira_ExtraCondensed/SairaExtraCondensed-SemiBoldItalic.woff') format('woff');
    font-weight: 600;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Saira ExtraCondensed';
    src:
        local('Saira ExtraCondensed Light Italic'),
        local('SairaExtraCondensed-LightItalic'),
        url('/assets/fonts/Saira_ExtraCondensed/SairaExtraCondensed-LightItalic.woff2') format('woff2'),
        url('/assets/fonts/Saira_ExtraCondensed/SairaExtraCondensed-LightItalic.woff') format('woff');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Saira ExtraCondensed';
    src:
        local('Saira ExtraCondensed Italic'),
        local('SairaExtraCondensed-Italic'),
        url('/assets/fonts/Saira_ExtraCondensed/SairaExtraCondensed-Italic.woff2') format('woff2'),
        url('/assets/fonts/Saira_ExtraCondensed/SairaExtraCondensed-Italic.woff') format('woff');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Saira ExtraCondensed';
    src:
        local('Saira ExtraCondensed Medium Italic'),
        local('SairaExtraCondensed-MediumItalic'),
        url('/assets/fonts/Saira_ExtraCondensed/SairaExtraCondensed-MediumItalic.woff2') format('woff2'),
        url('/assets/fonts/Saira_ExtraCondensed/SairaExtraCondensed-MediumItalic.woff') format('woff');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Saira ExtraCondensed';
    src:
        local('Saira ExtraCondensed Medium'),
        local('SairaExtraCondensed-Medium'),
        url('/assets/fonts/Saira_ExtraCondensed/SairaExtraCondensed-Medium.woff2') format('woff2'),
        url('/assets/fonts/Saira_ExtraCondensed/SairaExtraCondensed-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Saira ExtraCondensed';
    src:
        local('Saira ExtraCondensed ExtraBold Italic'),
        local('SairaExtraCondensed-ExtraBoldItalic'),
        url('/assets/fonts/Saira_ExtraCondensed/SairaExtraCondensed-ExtraBoldItalic.woff2') format('woff2'),
        url('/assets/fonts/Saira_ExtraCondensed/SairaExtraCondensed-ExtraBoldItalic.woff') format('woff');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Saira ExtraCondensed';
    src:
        local('Saira ExtraCondensed ExtraBold'),
        local('SairaExtraCondensed-ExtraBold'),
        url('/assets/fonts/Saira_ExtraCondensed/SairaExtraCondensed-ExtraBold.woff2') format('woff2'),
        url('/assets/fonts/Saira_ExtraCondensed/SairaExtraCondensed-ExtraBold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Saira ExtraCondensed';
    src:
        local('Saira ExtraCondensed SemiBold'),
        local('SairaExtraCondensed-SemiBold'),
        url('/assets/fonts/Saira_ExtraCondensed/SairaExtraCondensed-SemiBold.woff2') format('woff2'),
        url('/assets/fonts/Saira_ExtraCondensed/SairaExtraCondensed-SemiBold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Saira ExtraCondensed';
    src:
        local('Saira ExtraCondensed ExtraLight'),
        local('SairaExtraCondensed-ExtraLight'),
        url('/assets/fonts/Saira_ExtraCondensed/SairaExtraCondensed-ExtraLight.woff2') format('woff2'),
        url('/assets/fonts/Saira_ExtraCondensed/SairaExtraCondensed-ExtraLight.woff') format('woff');
    font-weight: 200;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Saira ExtraCondensed';
    src:
        local('Saira ExtraCondensed Regular'),
        local('SairaExtraCondensed-Regular'),
        url('/assets/fonts/Saira_ExtraCondensed/SairaExtraCondensed-Regular.woff2') format('woff2'),
        url('/assets/fonts/Saira_ExtraCondensed/SairaExtraCondensed-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}
