@mixin honda-padding($left: $gutter, $right: $gutter) {
    padding-left: $left;
    padding-right: $right;
}

.mobile-menu {
    &__title {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        text-align: center;
    }

    &__nav-icon {
        transform: scale(-1);
    }

    &__header {
        &--title {
            @include honda-padding;
        }
    }

    // This should match the value from the mobile-menu-trigger directive
    nav {
        height: calc(100% - 100px);

        .mobile-menu {
            &__breadcrumb {
                padding-top: 0;
            }

            &__goto,
            &__item {
                @include honda-padding;

                &.back,
                &.back:hover,
                &.back:focus {
                    border-color: $grey;
                    background-color: $grey-lighter;
                }
            }

            &__goto {
                text-transform: uppercase;
                font-weight: bold;
            }
        }
    }
}

.mobile-links {
    @include honda-padding(#{$gutter - 1});
}
