/****** COLORS *******/
$white: white;
$blue: #1a65c9;
$red: #cc1417;
$green: #007f4d;

$grey-darker: #585858;
$grey-dark: #999;
$grey: #e1e1e1;
$grey-light: #e6e6e6;
$grey-lighter: #f4f4f4;
$blue-dark: #215397;

$black: black;
$black-bis: #262626;
$brownish-grey: $grey-dark;

/****** General colors *******/
$foreground: $white;
$background: $white;

$hover: darken($red, 20%);
$primary: $red;
$text: $black;
$text-light: $grey-dark;
