$header-mobile-btn-color: $white;
$header-border: $border;
$header-bg-color: $black;
$header-padding-horizontal: 4rem;
$header-logo-x-small-padding-left: 1rem;
$header-logo-small-padding-left: $gutter-small;
$header-logo-large-padding-left: 0;
$header-logo-small-padding-right: 1rem;
$header-logo-large-padding-right: 0;

ncg-header {
    position: relative;
}

.header {
    position: relative;
    z-index: $index-header;
    background-color: $header-bg-color;
    box-sizing: content-box;

    &__backdrop {
        &::before {
            content: '';
            position: fixed;
            background-color: $black;
            left: 0;
            top: 0;
            right: 0;
            bottom: 0;
            z-index: $index-body-overlay;
            pointer-events: none;
            opacity: 0;
            transition: opacity $duration-fast ease-in-out;
        }

        &--is-open {
            &::before {
                opacity: 0.3;
            }
        }
    }

    &--animate {
        transition: transform $image-animation-duration-fast $smooth-in;
    }

    &--is-sticky {
        position: fixed;
        top: 0;
        transition: transform $image-animation-duration-fast $smooth-out;
        width: 100%;
    }

    &__container {
        @include is-fullwidth();
        margin: 0 auto;
        box-sizing: border-box;

        > .columns {
            height: $header-height-mobile;

            @include desktop {
                height: $header-height-desktop;
            }
        }
    }

    &__mobile-menu {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 5.9rem !important;
    }

    &__logo {
        position: relative;
        z-index: $index-header;
    }

    &__meta-menu {
        position: relative;
        align-items: center;
        z-index: $index-header;
        box-sizing: border-box;
        padding: 0;
        height: 3.5rem;

        // honda only: override default meta menu styles
        .dealer {
            &--has-name {
                flex-flow: unset;
            }
        }
    }

    &__meta-menu-wrapper {
        background: $background;
    }

    &__mobile-button {
        color: $header-mobile-btn-color;

        svg path {
            stroke: currentColor;
        }

        &:active,
        &:focus,
        &:hover {
            color: $header-mobile-btn-color;
        }
    }
}

.logo {
    display: flex;
    align-items: center;

    > img {
        padding-left: $header-logo-x-small-padding-left;

        @include from($mobile-large) {
            padding-left: $header-logo-small-padding-left;
        }

        @include from($desktop) {
            padding-right: $header-logo-large-padding-right;
            padding-left: $header-logo-large-padding-left;
        }
    }
}

.header-language {
    @include desktop {
        padding-left: 2rem;
        position: relative;

        &::before {
            content: '';
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
            height: 2rem;
            width: 0.1rem;
            background: $grey;
        }
    }

    .navbar {
        margin: 0 (-0.5rem);
    }

    &__item.navbar-item {
        padding: 0.5rem;
    }
}
