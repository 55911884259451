@import './_variables/color-variables';

/****** COMMOM DEFAULT VARIABLES *******/
@import '../../../../libs/common-styles/src/lib/styles/styles-variables';

/****** FONTS *******/
@import './fonts/fonts';

/****** VARIABLES *******/
@import './_variables/typography';
@import './_variables/variables';

/****** COMMON LIB *******/
@import '../../../../libs/common-styles/src/lib/styles/styles';

/****** COMPONENTS *******/
@import './components/navbar';

/****** FEATURES *******/
@import './features/footer';
@import './features/header';
@import './features/meta-menu';
@import 'features/main-menu';
@import 'features/mobile-menu';
