/****** FONT FAMILY CONFIG *******/
$font-family-base: 'proxima-nova-extra-condensed', 'Saira ExtraCondensed', 'arial';
$font-family-base-medium: 'proxima-nova-extra-condensed', 'Saira ExtraCondensed', 'arial';
$font-family-base-bold: 'proxima-nova-extra-condensed', 'Saira ExtraCondensed', 'arial';
$font-family-secondary: 'proxima-nova', 'Saira', 'arial';
$font-family-secondary-medium: 'proxima-nova', 'Saira', 'arial';

/****** FONT WEIGHT CONFIG *******/
$font-weight-medium: 600;
$font-weight-secondary: 600;
$font-weight-bold: 600;

/****** FONT SIZES CONFIG *******/
$font-size-mobile-menu: 1rem;
$font-size-tiny: 1rem;
$font-size-x-small: 1.1rem;
$font-size-small: 1.2rem;
$font-size-sub: 1.4rem;
$font-size-base: 1.4rem;
$font-size-navigation: 2.2rem;
$font-size-medium: 1.8rem;
$font-size-large: 2.4rem;
$font-size-large-mobile: 2.4rem;
$font-size-larger: 3rem;
$font-size-largest-mobile: 3.5rem;
$font-size-largest: 5rem;
$font-size-huge: 5.8rem;

$line-height-footer: 1.2;
$line-height-base: 1.78;
$line-height-large: 2;

$letter-spacing-base: normal;
$letter-spacing-x-small: normal;
$letter-spacing-small: normal;
$letter-spacing-medium: normal;
$letter-spacing-large: normal;

/****** KEYS *******/
$primary-heading-size-key: huge;
$primary-heading-family-key: base;
$primary-heading-line-key: normal;
$primary-heading-letter-space-key: negative-large;
$primary-heading-weight-key: normal;
$primary-heading-text-transform: uppercase;

$secondary-heading-weight: normal;
$secondary-heading-size-key: largest;
$secondary-heading-family-key: base;
$secondary-heading-line-key: normal;
$secondary-heading-text-transform: uppercase;
$secondary-heading-letter-space-key: base;

$tertiary-heading-weight: normal;
$tertiary-heading-size-key: larger;
$tertiary-heading-family-key: base;
$tertiary-heading-line-key: normal;
$tertiary-heading-letter-space-key: base;
$tertiary-heading-mobile-size: base;
$tertiary-heading-mobile-family: medium;
$tertiary-heading-mobile-line-height: base;
$tertiary-heading-mobile-weight: normal;
$tertiary-heading-text-transform: uppercase;

$small-heading-size-key: large;
$small-heading-family-key: base;
$small-heading-line-key: small;

$sub-heading-size-key: medium;
$sub-heading-family-key: base;
