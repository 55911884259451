$meta-item-color-touch: $black;

.meta-menu {
    .navbar {
        height: 100%;
    }

    .navbar-menu {
        padding: 0;
    }

    .navbar-item {
        justify-content: center;
        align-items: center;
        font-size: map-get($font-sizes, mobileMenu);
        min-width: auto;
        line-height: 1.1;

        @include until($desktop) {
            padding: 1.3rem 0.5rem;
            color: $meta-item-color-touch;
        }

        @include desktop {
            padding: 0 1.2rem;
            font-size: $font-size-navigation-small;
        }

        &__search {
            @extend .is-hidden-touch;
            min-height: 0;
        }

        &.has--icon {
            flex-flow: wrap row;
        }

        &--icon {
            margin-right: 0.7rem;
            color: $red;

            > svg {
                max-width: 2rem;
                max-height: 2rem;
            }
        }
    }
}
